import React, {useRef, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import arrowImage from '../newsRes/arrow-white.png'
import arrowSeeMore from "./arrow.png";
import arrowHover from './arrow-blue.png'
import './NewsCards.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


function SeeMoreButton() {
  const [arrowSrc, setArrowSrc] = useState(arrowSeeMore); 

  const navigate = useNavigate();

  const handleMouseEnter = () => setArrowSrc(arrowHover); 
  const handleMouseLeave = () => setArrowSrc(arrowSeeMore);

  return (
    <button
      className="seeMoreButton"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => navigate('/news')}
    >
      <p className='seeMore'>See More</p>
      <img src={arrowSrc} alt="Arrow" className='arrowImage'/>
    </button>
  );
}

function NewsCards({ newsArticles = [] }) {
  const navigate = useNavigate();
  const sliderRef = useRef();
  let shownImages = 3;
  if (newsArticles.length < 3){
    shownImages = newsArticles.length
  }
  
  const copyNewsArticles = newsArticles.slice(0, 6);

  const sliderContainerClass = `news-container ${
    shownImages === 1 ? "one-slide" : shownImages === 2 ? "two-slides" : ""
  }`;

  const settings = {
    infinite: true,
    speed: 500,
    dots: false,
    prevArrow: false,
    nextArrow: false,
    slidesToShow: shownImages,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        },
        prevArrow: false,
        nextArrow: false,
      }
    ]
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className={sliderContainerClass}>
      <Slider ref={sliderRef} {...settings}>
        {copyNewsArticles.map((article, index) => (
          <div className="news-block" key={index} onClick={() => navigate(`/news/${article.id}`, { state: { article } })}>
            <img src={article.previewImage} alt={article.title}/>
            <div className="news-info">
              <h3>{article.title}</h3>
              <span className="news-date">{new Date(article.date).toLocaleDateString()}</span>
            </div>
          </div>
        ))}
      </Slider>
      <div className='navigation'>
      <div className='dummyDiv'></div>
        <div className='news-navigation'>
          <button onClick={handlePrevious} className='navigation-button'><img src={arrowImage} alt='arrowImage' width={'15px'}/></button>
          <button onClick={handleNext}className='navigation-button'><img src={arrowImage} alt='arrowImage' id='nexzImg' width={'15px'}/></button>
        </div>
        <SeeMoreButton/>
      </div>
    </div>
  );
}

export default NewsCards;