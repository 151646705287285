import React from 'react';
import { useTranslation } from 'react-i18next';
import './general.css'; 


function MaturiaSite() {

  const { t } = useTranslation();

  const departments = t('Marturia.departments', { returnObjects: true });
  const banner = `https://res.cloudinary.com/dlgnhmhh3/image/upload/v1710916018/banners/mfnwdfckwqo2xcs8urlr.jpg`;

  return (
    <div className="ministry-page">
      <div className="banner darker" style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', 
    backgroundPosition: 'top'}}>
        <div className='banner-content'>
          <h1>{t('Marturia.header')}</h1>
          <div className='divider'></div>
        </div>
      </div>
      <div className='ministry-content'>
        <section className='ministry-introduction'>
          <h2>{t('ministryPage.about')}</h2>
          <p>{t('Marturia.description')}</p>
        </section>
        <section className='ministry-departments'>
          <h1>{t('ministryPage.depart')}</h1>
          {departments.map((department, index) => (
              <article key={index} className='indent'>
                  <h3>{department.titel}</h3>
                  <p>{department.description}</p>
                  {department.program && Array.isArray(department.program) && (
                      <ul className='department-programm'>
                          {department.program.map((item, index) => (
                              <li key={index}>{item}</li>
                          ))}
                      </ul>
                      )}
              </article>
            ))}
        </section>
       {/* {images.map((imageSrc, index) => (
            <img
              key={index}
              src={imageSrc}
              alt={`Church View ${index + 1}`}
              className="department-image"
            />
       ))}*/}
      </div>

   </div>
  );
}

export default MaturiaSite;

