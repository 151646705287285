import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './StorrySection.css'; 
import arrowImage from "./arrow.png";
import arrowHover from './arrow-blue.png'

function SeeMoreButton() {
  const [arrowSrc, setArrowSrc] = useState(arrowImage); 

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMouseEnter = () => setArrowSrc(arrowHover); 
  const handleMouseLeave = () => setArrowSrc(arrowImage); 

  return (
    <button
      className="seeMoreButton"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => navigate('/about/history')}
    >
      <p className='seeMore'>{t('seeMoreBtn')}</p>
      <img src={arrowSrc} alt="Arrow" className='arrowImage'/>
    </button>
  );
}

function StorrySection() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const imageUrl = 'https://res.cloudinary.com/dlgnhmhh3/image/upload/v1710731938/homeRes/Kantor.png'

  function handleClick() {
    navigate('/about/history'); 
  }

  return (
      <div className='story'>
        <div>
          <h2>{t('storySection.title')}</h2>
          <p>{t('storySection.description')}</p>
          <div className="container">
            <div>
              <div className="story-card" onClick={handleClick}>
                <h3>{t('storySection.storyTitle')}</h3>
                <p>{t('storySection.story')}</p>
                <p className='moreButton'>{t('storySection.more')}</p>
              </div>
              <SeeMoreButton/>
            </div>
            <div className="story-image">
              <img src={imageUrl} alt="Church Activity" />
            </div>
          </div>
        </div>
      </div>
  );
}

export default StorrySection;