import React from 'react';
import Banner from './homeRes/Banner';
import StorySection from './homeRes/StorrySection';
import QuickLinksBox from './homeRes/QuickLinksBox';
import NewsCards from './homeRes/NewsCards'

function HomePage({churches, newsArticles}) {
  return (
    <div>
      <Banner />
      <StorySection />
      <QuickLinksBox churches={churches}/>
      <NewsCards newsArticles={newsArticles}/>
    </div>
  );
}

export default HomePage;
