import React, { useState, useMemo, useRef, useCallback } from "react";
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './ChurchMap.css'
import { useNavigate } from 'react-router-dom';

import dot from './record.png';

const customIcon = new L.Icon({
  iconUrl: dot,
  iconSize: [27, 25],
  iconAnchor: [12, 25],
  popupAnchor: [0, -25],
});

const customIcon2 = new L.Icon({
  iconUrl: dot,
  iconSize: [32.5, 32.5],
  iconAnchor: [12, 32.5],
  popupAnchor: [0, -25],
});

const RenderIcons = ({ church }) => {
  const [dotSrc, setDotSrc] = useState(customIcon);
  const markerRef = useRef();
  const navigate = useNavigate();

  const handleNavigateToChurchDetail = useCallback(() => {
    navigate(`/churches/${church.id}`);
  }, [navigate, church.id]);

  const eventHandlers = useMemo(
    () => ({
      click: handleNavigateToChurchDetail,
      mouseover() {
        if (markerRef) markerRef.current.openPopup();
        setDotSrc(customIcon2);
      },
      mouseout() {
        if (markerRef) markerRef.current.closePopup();
        setDotSrc(customIcon);
      }
    }),
    [handleNavigateToChurchDetail]
  );

  return (
    <Marker
      ref={markerRef}
      position={[church.latitude, church.longitude]}
      icon={dotSrc}
      eventHandlers={eventHandlers} 
    >
      <Popup>
        <img src={church.previewImage} alt="Church Team" width='300' />
        <div className="popup-text-block">
          <h3>{church.name}</h3>
          <p>{church.shortDescription}</p>
        </div>
      </Popup>
    </Marker>
  );
};




function ChurchesMap({ churches }) {

  const defaultLatitude = -7.3305;
  const defaultLongitude = 110.5084;

  return (
    <MapContainer center={[defaultLatitude, defaultLongitude]} zoom={12} scrollWheelZoom={true} style={{ height: '600px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {churches.map((church) => (
        <RenderIcons church={church} />
      ))}
    </MapContainer>
  );
}

export default ChurchesMap;
