import React from 'react';
import { useTranslation } from 'react-i18next'; 
import './MissionPage.css';

function MissionPage() {
  const { t } = useTranslation();
  const statements = t('missionPage.statements', { returnObjects: true });
  const bannerURL = `https://res.cloudinary.com/dlgnhmhh3/image/upload/v1710910094/banners/pzhvtibd7zgdgukdiocs.jpg`;

  return (
    <div className="mission-page">
      <div className="banner darker" style={{ backgroundImage: `url(${bannerURL})`}}>
        <div className="banner-content">
          <h1>{t('missionPage.title')}</h1>
          <div className='divider'></div>
          <p>{t('missionPage.description')}</p>
        </div>
      </div>
      {statements.map((statement, index) => (
        <section key={index} className="mission-section">
          <div className="mission-header">
            <h2>{statement.title}</h2>
            {statement.description && <p className="mission-description">{statement.description}</p>}
          </div>
          <ol className="mission-text">
            {statement.text.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ol>
        </section>
      ))}
    </div>
  );
}

export default MissionPage;
