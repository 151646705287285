import React from 'react';
import { useTranslation } from 'react-i18next';
import './Banner.css'; 

function Banner() {

  const { t } = useTranslation();
  const bannerURl = `https://res.cloudinary.com/dlgnhmhh3/image/upload/v1710731899/homeRes/churchBanner.jpg`; 

  return (
    <div className="banner darker" style={{ backgroundImage: `url(${bannerURl}) `, backgroundSize: 'auto',
    backgroundPosition: 'left bottom'}}>
        <h1 className="banner-content">{t('banner.welcomeMessage')}</h1>
      </div>
  );
}

export default Banner;
