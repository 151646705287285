import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import NewsCards from './NewsCardsChruch'
import './ChurchDetailPage.css';


function ChurchDetailPage({ churches }) {
  const [church, setChurch] = useState(null);
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchChurchDetails = () => {
      const churchDetails = churches.find(ch => ch.id === id);
      setChurch(churchDetails);
    };
    fetchChurchDetails();
  }, [id, churches]);

  if (!church) return <div>Loading...
  </div>;

  return (
    <div className="church-detail-page">
      <div className="banner" style={{ backgroundImage: `url(${church.bannerUrl})`, backgroundSize: 'cover', 
    backgroundPosition: 'center center'}}>
        <div className='banner-content'>
          <h1>{church.title}</h1>
          <div className='divider'></div>
        </div>
      </div>
      <div className='church-detail-content'>
        <div className='church-detail-text'>
          <h1>{church.name}</h1>
          <h2>{t('churchPages.history')}</h2>
          {church.description.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          <h2>{t('churchPages.service')}</h2>
          {church.services.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          <h2>{t('churchPages.contact')}</h2>
          <p>{church.contact.name}</p>
          <p>{church.contact.phone}</p>
          <p>{church.contact.address}</p>
        </div>
        {church.images.map((imageSrc, index) => (
            <img
              key={index}
              src={imageSrc}
              alt={`Church View ${index + 1}`}
              className="church-image"
            />
          ))}
      </div>

            {/*<NewsCards/>*/}

   </div>
  );
}

export default ChurchDetailPage;

