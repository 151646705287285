import React from 'react';
import { useTranslation } from 'react-i18next';
import './aboutRes/sinodePage.css';

const chairmen  = [
  { id: 1, name: 'Pdt. Akris Mujiono. M. Si', role: 'Chairman', headshot: `headshots/akris` },
  { id: 2, name: 'Pdt. Waluyo. M Si', role: 'Sekretaris 1', headshot: 'headshots/waluyo' },
  { id: 3, name: 'Pdt. Juwasto. S.Th', role: 'Sekretaris 2', headshot: `headshots/juwasto` },
  { id: 4, name: 'Pdt. Paini M. Si', role: 'Bendahara 1', headshot: `headshots/paini` },
  { id: 5, name: 'Pdt. Yosafat Budi Wahyono', role: 'Bendahara 2', headshot: `headshots/empty` },
  { id: 6, name: 'Suko Dwi Nugroho', role: 'Anggota', headshot: `headshots/SukoDwiNughruho	` },
  { id: 7, name: 'Pdt. Yohanes Sumarno', role: 'Anggota', headshot: `headshots/empty` },
];

const employees = [
  { id: 1, name: 'Sumarmi', role: 'Kasir dan Pembuku',  headshot: `headshots/sumarmi` },
  { id: 2, name: 'Sri Purwati', role: 'Administrasi Umum', headshot: `headshots/sriPurwati` },
  { id: 3, name: 'Condrat Leba Piga', role: 'Sekretaris Pelaksana Bidang Koinonia', headshot: `	headshots/condrat` },
  { id: 4, name: 'Andhita Ayu Larasati', role: 'Sekretaris Pelaksana Bidang Diakonia', headshot: `headshots/anditah` },
  { id: 5, name: 'Harry Trisna Raharjo', role: 'Office Boy', headshot: `headshots/harry` },
  { id: 6, name: 'Demianus Nataniel', role: 'Sekretaris Pelaksana Bidang Maturia', headshot: `headshots/empty` },
];



const Section = ({ title, people }) => (
  <div className="section">
    <h2>{title}</h2>
    <div className="employee-grid">
      {people.map(person => (
        <EmployeeCard key={person.id} employee={person} />
      ))}
    </div>
  </div>
);

const EmployeeCard = ({ employee }) => {
  const cloudName ='dlgnhmhh3';
  const baseUrl = `https://res.cloudinary.com/${cloudName}/image/upload/v1710729048/`; 
  const imageUrl = `${baseUrl}${employee.headshot}.png`;


  return(
  <div className="employee-card">
    <div className="employee-image-wrapper">
      <img src={imageUrl} alt={`Headshot of ${employee.name}`} className="employee-image"/>
    </div>
    <div className="employee-info">
      <h3>{employee.name}</h3>
      <p className="role">{employee.role}</p>
    </div>
  </div>
  )};

const AboutPage = () => {
  
  const { t } = useTranslation();
  const bannerURl = `https://res.cloudinary.com/dlgnhmhh3/image/upload/v1710916844/banners/x6fbvdgpsea9bzumsvfx.png`; 

  return(
    <div>
      <div className="banner darker" style={{ backgroundImage: `url(${bannerURl}) `, backgroundSize: 'cover',
    backgroundPosition: 'center'}}>
        <div className="banner-content">
          <h1>{t('aboutPage.title')}</h1>
          <div className="divider"></div>
          <p>{t('aboutPage.description')}</p>
        </div>
      </div>
      <div className="about-page">
        <Section title="Chairman" people={chairmen} />
        <Section title="Employees" people={employees} />
      </div>
    </div>
  );
  }
export default AboutPage;
