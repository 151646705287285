import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './NewsDetailPage.css';

function NewsDetail() {
  const [article, setArticle] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const foundArticle = location.state.article;
    setArticle(foundArticle);
  }, [location.state.article]); // Assuming `id` is not needed for dependency if you're not fetching the article by ID here

  if (!article) return <div>Loading...</div>;

  // Function to generate JSX for article's content
  const renderContent = (contentArray) => {
    return contentArray.map((item, index) => (
      <React.Fragment key={index}>
        {item.title && <h3>{item.title}</h3>} {/* Conditionally render the title if it exists */}
        <p>{item.text}</p> {/* Render the text */}
      </React.Fragment>
    ));
  };
  
  return (
    <div>
      <div className="banner" style={{ backgroundImage: `url(${article.bannerImage})`}}>
        <div className="banner-content">
          <h1>{article.title}</h1>
        </div>
      </div>

      <div className="news-detail-page">
        
        <div className="news-content">
          {renderContent(article.content)}
        </div>

        {article.images && article.images.length > 0 && (
          <div className="news-images">
            {article.images.map((imageSrc, index) => (
              <img key={index} src={imageSrc} alt={`Visual ${index + 1}`} className="news-article-image" />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default NewsDetail;
